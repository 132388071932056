import React from 'react';
import { withRouter } from 'react-router-dom';

import { OpenSourceCard } from '../open-source'
import { BlogCard } from '../blog'
import { LoadImages, redirectTo } from '../utils'
import './home.shared.css'
import './home.desktop.css'
import './home.mobile.css'

const BulletPoint = ({ point }) => {
	return (
		<div className="bullet-point">
			<img src={require(`../assets/tick.png`)} alt='' />
			<div className="point">{point}</div>
		</div>
	)
}

const Project = ({ title, text, images, phone, isLeft }) => {
	const shapes = isLeft ? 'shapes' : 'shapes'

	return (
		<div className={`project-main ${isLeft ? 'is-left' : 'is-right'}`}>
			<div className="body">
				<div className="title">{title}</div>
				<div className="text">{text}</div>
				<div className="images"><LoadImages images={images} /></div>
			</div>
			<div className="phone">
				<img src={require(`../assets/${phone}.png`)} alt='' />
			</div>
			<div className="shapes">
				<img src={require(`../assets/${shapes}.png`)} alt='' />
			</div>
		</div>
	)
}

export const Home = withRouter(({ isMobile, history }) => {
	return (
		<div className={`home ${isMobile ? 'mobile' : 'desktop'}`}>
			<div className="banner">
				<div className="body">
					<div className="header">
						Launch your digital product faster.
					</div>
					
				</div>
				<div className="splash">
					<img src={require(`../assets/blast-large.png`)} alt='' />
				</div>
			</div>
			<div className="bullet-point-section">
						<div className="bullet-point-header">
							Who we are
						</div>
						<div className="bullet-points">
							<BulletPoint point={`Blast Engine is a technology innovation company that helps brands build disruptive digital experiences.`} />
							<BulletPoint point={`From engaging web & mobile applications, to robust & scalable servers, we deliver lightning-fast and maintainable software that help your business succeed.`} />
							<BulletPoint point={`Our team works towards building robust applications with latest technologies like ReactJS, React Native, NodeJS and Google Cloud.`} />
						</div>
					</div>
			<div className="projects">
				<div className="top-section">
					<div className="header">
						Projects
					</div>
					<div className="underline">
						<div className="left" />
						<div className="right" />
					</div>
				</div>
				<Project
					title={'Smack Chat'}
					text={
						`Smack Chat is an online debating platform designed for people with a knack for good debates. In Smack Chat, you can debate with anyone -friends or strangers- publicly in front 
						of a live audiene. Each debate will be a timed, and there is an indicator of which party did the audience like or agreed to more! You can strengthen your ideas or 
						see things from a different point of view.`
					}
					images={['react', 'node', 'google-cloud']}
					phone={'smackchat'}
					isLeft
				/>
				{ isMobile ? <div className='project-separator'></div> : null }
				<Project
					title={'Crafty Film'}
					text={
						`Crafty Film is an online ordering application designed for movie sets. From this app, you can easily order your favorite breakfast or snack, or get notified once your food truck brings
						something new and delicious! By using this app, not only do you keep you and your colleagues safe by avoiding crowds, but also you save time by skipping the line. `
					}
					images={['react', 'node', 'google-cloud']}
					phone={'crafty'}
				/>
				{/* <div className="see-more-container">
					<div className="see-more" onClick={() => redirectTo({ url: 'projects', history })}>
						See More &nbsp; →
					</div>
				</div> */}
			</div>
			{/* <div className="blog">
				<div className="top-section">
					<div className="header">
						Blog
					</div>
					<div className="underline">
						<div className="left" />
						<div className="right" />
					</div>
				</div>
				<BlogCard
					isMobile={isMobile}
					logo={'css'}
					title={'How to do CSS'}
					text={'Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed'}
					name={"John Smith"}
					fields={['CSS', 'DEVELOPMENT']}
				/>
				<BlogCard
					isMobile={isMobile}
					logo={'js'}
					title={'Intro to Javascript'}
					text={'Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed'}
					name={"John Smith"}
					fields={['JAVASCRIPT', 'DEVELOPMENT']}
				/>
				<div className="see-more" onClick={() => redirectTo({ url: 'blog', history })}>
					See More &nbsp; →
				</div>
			</div> */}
			<div className="open-source">
				<div className="top-section">
					<div className="header">
						Open Source
					</div>
					<div className="underline">
						<div className="left" />
						<div className="right" />
					</div>
				</div>
				<OpenSourceCard
					isMobile={isMobile}
					icon={'firework'}
					title={'Firework'}
					text={'A Google Firebase and React integration framework'}
					stars={'390'}
					images={[]}
				/>
				<OpenSourceCard
					isMobile={isMobile}
					icon={'mixable'}
					title={'Mixable'}
					text={'A JavaScript Mixin library'}
					stars={'390'}
					images={[]}
				/>
				{/* <div className="see-more" onClick={() => redirectTo({ url: 'open-source', history })}>
					See More &nbsp; →
				</div> */}
			</div>
		</div>
	)
})