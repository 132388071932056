import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faStar } from '@fortawesome/free-solid-svg-icons'

import { SearchBar } from '../search-bar'
import { LoadImages } from '../utils'
import './open-source.css'

export const OpenSourceCard = ({ isMobile, title, text, icon, stars, images }) => {
    return (
        <div className={`open-source-card-main ${isMobile ? 'mobile' : 'desktop'}`}>
            <div className="body">
                <div className="side-icon"><LoadImages images={icon}/></div>
                <div className="body-main">
                    <div className="title">{title}</div>
                    <div className="text">{text}</div>
                </div>
            </div>
            {/* <div className="images">
                <LoadImages images={images}/>
            </div> */}
        </div>
    )
}

export const OpenSource = ({ isMobile }) => {
    return (
        <div className={`open-source-main ${isMobile ? 'mobile' : 'desktop'}`}>
            {/* <div className={`search-bar ${isMobile ? 'mobile' : 'desktop'}`}>
                <SearchBar placeholderText={"libraries, frameworks"} isMobile={isMobile}/>
            </div> */}
            <OpenSourceCard
                isMobile={isMobile}
                icon={'firework'}
                title={'Firework'}
                text={'A Google Firebase and React integration framework'}
                stars={'390'}
                images={[]}
            />
            <OpenSourceCard
                isMobile={isMobile}
                icon={'mixable'}
                title={'Mixable'}
                text={'A JavaScript Mixin library'}
                stars={'390'}
                images={[]}
            />
        </div>
    )
}