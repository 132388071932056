import React from 'react';
import { BrowserRouter, Switch, Route } from 'react-router-dom';

import { responsiveComponent } from '@blast-engine/responsive-component'

import { Frame } from './frame'
import { Home } from './home'
import { OpenSource } from './open-source'
import { Blog } from './blog'
import { Projects } from './projects'
import { OurTeam } from './our-team'
import './App.css';
import './fonts.css'

const App = 
  responsiveComponent(
    class App extends React.PureComponent {    
      
      render() {
        const { isMobile } = this.props
        return (
            <BrowserRouter>
              <Frame isMobile={isMobile}>
                <Switch>
                  <Route path='/open-source' render={() => (
                    <OpenSource isMobile={isMobile}/>
                  )}/>
                  {/* <Route path='/blog' render={() => (
                    <Blog isMobile={isMobile}/>
                  )}/> */}
                  <Route path='/projects' render={() => (
                    <Projects isMobile={isMobile}/>
                  )}/>
                  {/* <Route path='/our-team' render={() => (
                    <OurTeam isMobile={isMobile}/>
                  )}/> */}
                  <Route path='/' render={() => (
                    <Home isMobile={isMobile}/>
                  )}/>
                </Switch>
              </Frame>
            </BrowserRouter>
        )
      }
    }
  )

export default App
