import React from 'react';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSearch } from '@fortawesome/free-solid-svg-icons'

import './search-bar.css'

export class SearchBar extends React.PureComponent{ //= ({ placeholderText }) => {
    state = { searchValue: '' }

    handleChange = e => {
        this.setState({ searchValue: e.target.value })
    }

    handleKeyDown = e => {
        if(e.key === 'Enter'){
          this.handleSubmit()
        }
      }

    handleSubmit = () => {
        const { searchValue } = this.state
        
        alert(searchValue)
    }

    render(){
        const { placeholderText, isMobile } = this.props
        const { searchValue } = this.state

        return (
            <div className={`search-area
             ${ isMobile ? 'mobile' : 'desktop' }`}>
                <div className="text">Find: </div>
                <input 
                    className="input"
                    placeholder={placeholderText}
                    onChange={this.handleChange}
                    onKeyDown={this.handleKeyDown}
                    value={searchValue}
                />
                <div className="button" onClick={this.handleSubmit}>
                    <div className="search-icon"><FontAwesomeIcon icon={faSearch}/></div>
                </div>
            </div>
        )
    }
}