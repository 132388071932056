import React from 'react'
import { withRouter } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTimes } from '@fortawesome/free-solid-svg-icons'

import { LoadImages, redirectTo, validateEmail } from '../../utils'
import { firebase } from '../../firebase.singleton'
import './sidebar.css'
import './sidebar.desktop.css'
import './sidebar.mobile.css'


export const SidebarEntry = withRouter(({ icon, path, text, history }) => {
    const isActive = window.location.pathname === path

    return(
        <div 
            className="body" 
            onClick={() => redirectTo({ url: path, history })}        
        >
            <LoadImages images={`${icon}${isActive ? '-active' : ''}`}/>
            <div 
                className={`text ${isActive ? 'active' : ''}` } 
            >{text}</div>
        </div>
    )
})

class SubmitBox extends React.PureComponent{
    state = { 
        hasSubmitted: false,
        error: false,
        email: '',
        message: ''  
    }

    handleSubmit = () => {
        const { email, message } = this.state

        if(!validateEmail(email)){
            this.setState({ error: 'INVALID EMAIL'})
        }else if(!message.length){
            this.setState({ error: "NO MESSAGE ENTERED"})
        }else{
            try{
                firebase.database().ref('emails/').push({ email, message }).then(
                    this.setState({ hasSubmitted: true })
                ).catch(e => this.setState({ error: 'SOMETHING WENT WRONG'}))
            }catch(e){
                this.setState({ error: 'SOMETHING WENT WRONG'})
            }
        }
    }
    
    refreshSubmit = () => this.setState({ 
        hasSubmitted: false ,
        error: false,
        email: '',
        message: ''
    })

    handleFormChange = e => {
        const { value, name } = e.target

        this.setState({ [name] : value })
    }

    render(){
        const { hasSubmitted, error } = this.state
        const { refreshSubmit, handleSubmit, handleFormChange } = this

        

        const Submitted = () => (
            <>
                <div className="submit-circle"><LoadImages images={"check"}/></div>
                <div className="in-contact">WE'LL BE IN CONTACT SOON</div>
                <div className="contact-us" onClick={refreshSubmit}><LoadImages images={"reload"}/></div>
            </>
        )

        const NotSubmitted = ({ error }) => (
            <>
                <div className="submit-circle red">
                    <FontAwesomeIcon 
                        className='icon'
                        icon={faTimes} 
                    />
                </div>
                <div className="in-contact red">{error} <br/> PLEASE TRY AGAIN</div>
                <div className="contact-us" onClick={refreshSubmit}><LoadImages images={"reload"}/></div>
            </>
        )

        return (
            !!error ? (
                <NotSubmitted error={error}/>
            ) : hasSubmitted ? (
                    <Submitted/>
                ) : (
                    <>
                        <textarea className="email" name="email" placeholder="Email" onChange={handleFormChange}/>
                        <textarea className="message" name="message" placeholder="Message..." onChange={handleFormChange}/>
                        <div className="contact-us" onClick={handleSubmit}>Contact Us</div>
                    </>
                )
        )
    }

}

export class Sidebar extends React.PureComponent{

    SidebarEntry = withRouter(({ icon, path, text, history }) => {
        const isActive = window.location.pathname === path
    
        return(
            <div 
                className="body" 
                onClick={() => redirectTo({ url: path, history })}        
            >
                <LoadImages images={`${icon}${isActive ? '-active' : ''}`}/>
                <div 
                    className={`text ${isActive ? 'active' : ''}` } 
                >{text}</div>
            </div>
        )
    })

    state = {
        expanded: true
    }

    _onBlur = () => {
        const { isMobile } = this.props
        isMobile && this.setState({ expanded: true })
    }

    _onFocus = () => {
        const { isMobile } = this.props
        isMobile && this.setState({ expanded: false })
    }

    render(){
        const { isMobile } = this.props 
        const { _onBlur, _onFocus } = this
        const { expanded } = this.state

        return (
            <div className={`sidebar ${isMobile ? 'mobile' : 'desktop'}`} onFocus={_onFocus} onBlur={_onBlur}>
                <div className={`switch ${expanded ? 'full' : 'mini'}`}>
                    <SidebarEntry icon={`home`} path={'/'} text="Home"/>
                    {/* <SidebarEntry icon={`blog`} path={'/blog'} text="Blog"/> */}
                    {/* <SidebarEntry icon={'projects'} path={'/projects'} text="Projects"/> */}
                    {/* <SidebarEntry icon={'open-source'} path={'/open-source'}text="Open Source"/> */}
                    {/* <SidebarEntry icon={'our-team'} path={'/our-team'} text="Our Team"/> */}
                </div>
                <div className="project-request">
                    <div className="header">Have A Project For Us?
                        <div className="underline">
                            <div className="left"/>
                            <div className="right"/>
                        </div>
                    </div>
                    <SubmitBox/>
                </div>
                <div className='affiliates'>
                    <LoadImages images={['facebook', 'twitter', 'instagram']}/>
                </div>
                <div className='copyright'>
                    @ COPYRIGHT BLAST ENGINE
                </div>
            </div>
        )
    }
}