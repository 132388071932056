import React from 'react'

import { Navbar } from './navbar'
import { Sidebar } from './sidebar'
import './frame.css'
import './frame.desktop.css'
import './frame.mobile.css'

export class Frame extends React.PureComponent {

  state = { sidebarOpen: false }
  openSidebar = () => this.setState({ sidebarOpen: true })
  closeSidebar = () => this.setState({ sidebarOpen: false })

  render() {
    const { isMobile, children } = this.props
    const { sidebarOpen } = this.state
    const { openSidebar, closeSidebar } = this
    return (
      <>
        <Navbar 
          isMobile={isMobile} 
          openSidebar={openSidebar}
          sidebarOpen={sidebarOpen}
          closeSidebar={closeSidebar}
        />
      <div className={`
        frame 
        ${isMobile ? 'mobile' : 'desktop'} 
        ${sidebarOpen && 'sidebar-open'}
      `}>
        {
          isMobile ? (
            <>
              <div className='non-sidebar'>
                <div className='content'>
                  {children}
                </div>
              </div>
              <div className='sidebar-container'>
                <Sidebar isMobile={isMobile} closeSidebar={closeSidebar}/>
              </div>
              {sidebarOpen && <div className="overlay" onClick={closeSidebar}/>}
            </>      
          ) : (
            <>
              <Sidebar isMobile={isMobile}/>
              <div className='non-sidebar'>
                <div className='content'>
                  {children}
                  <div className="right-buffer"/>
                </div>
              </div>
            </>
          )
        }
      </div>
    </>)
  }
  
}