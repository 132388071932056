import React from 'react';

// export const redirectTo = ({ url, openNewTab }) => window.open(url, openNewTab ? "_blank" : "_self")

export const redirectTo = ({ url, history }) => 
    !!history
        ? history.push(url)
        : window.open(url, "_blank")

export const LoadImages = ({ path = '',  images, ext = 'png' }) => {

    const img = images instanceof Array ? images : [images]
    
    return img.map((img, i) => 
        <div className="image-container" key={i}>
            <img src={require(`../assets/${path}${img}.${ext}`)} alt=''/>
        </div> 
    )
}

export const LoadText = ({ text }) => {

    const txt = text instanceof Array ? text : [text]
    
    return txt.map((txt, i) => 
        <div className="text-container" key={i}>{txt}</div> 
    )
}

export const validateEmail = (email) => {
    const re = /[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?/
    return re.test(String(email).toLowerCase());
  }
