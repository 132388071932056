import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faBars, faTimes } from '@fortawesome/free-solid-svg-icons'

import { LoadImages } from '../../utils'
import './navbar.css'
import './navbar.desktop.css'
import './navbar.mobile.css'


export const Navbar = ({ isMobile, openSidebar, closeSidebar, sidebarOpen }) => (
  <div className={`navbar ${isMobile ? 'mobile' : 'desktop'}`}>
    { 
      isMobile
      ? <>
          <FontAwesomeIcon 
            className='sidebar-icon'
            icon={sidebarOpen ? faTimes : faBars} 
            onTouchEnd={sidebarOpen ? closeSidebar : openSidebar}
          />
          <div className="logo">
            <LoadImages images={'blast'}/>
            <div className="title">Blast Engine</div>
          </div>
        </>
    : <>
        <div className="blast-logo">
          <LoadImages images={'blast'}/>
          <div className="title">Blast Engine</div>
        </div>
        {/* <div className="right-side">
          <div className="left-buffer"/>
          <div className='affiliates'>
            <LoadImages images={['facebook', 'twitter', 'instagram']}/>
          </div>
          <div className="right-buffer"/>
        </div> */}
      </>
    }
  </div>
)