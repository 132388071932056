import React from 'react';

import { LoadImages } from '../utils'
import './projects.css'

export const ProjectsCard = ({ title, text, phone, color, images }) => {
    return (
            <div className="body">
                <div className="body-top" style={{ backgroundColor : color }}>
                    <div className="phone"><LoadImages images={phone}/></div>
                    <div className="shapes"><LoadImages images={'shapes-nocolor'}/></div>
                </div>
                <div className="body-main">
                    <div className="title">{title}</div>
                    <div className="text">{text}</div>
                </div>
                <div className="images">
                    <LoadImages images={images}/>
                </div>
            </div>
    )
}

export const Projects = ({ isMobile }) => {
    return (
        <div className={`projects-main ${isMobile ? 'mobile' : 'desktop'}`}>
            <ProjectsCard
                title={'Smack Chat'}
                text={'Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut ero labore et dolore magna aliqua'}
                color={"#AB60B8"}
                phone={'phone'}
                images={['node', 'unity', 'node']}
            />
            <ProjectsCard
                title={'Smack Chat'}
                text={'Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut ero labore et dolore magna aliqua'}
                color={"#EF6C00"}
                phone={'phone'}
                images={['node', 'unity']}
            />
            <ProjectsCard
                title={'Smack Chat'}
                text={'Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut ero labore et dolore magna aliqua'}
                color={"#B2BF2D"}
                phone={'phone'}
                images={['node', 'unity']}
            />
            <ProjectsCard
                title={'Smack Chat'}
                text={'Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut ero labore et dolore magna aliqua'}
                color={"#3F51B5"}
                phone={'phone'}
                images={['node', 'unity']}
            />
        </div>
    )
}