import * as fb from 'firebase'

const config = {
    apiKey: "AIzaSyDHB4zUpX7uF7gnLlUPLZJ1-riBGHv5sZ4",
    authDomain: "blast-website-b3d5d.firebaseapp.com",
    databaseURL: "https://blast-website-b3d5d.firebaseio.com",
    projectId: "blast-website-b3d5d",
    storageBucket: "blast-website-b3d5d.appspot.com",
    messagingSenderId: "569684881752"
};

fb.initializeApp(config);

window.firebase = fb

export const firebase = fb